import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  styled,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionCard from '../accordion-card'

const AccordionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 4rem;
  padding-right: 4rem;
`

const AccordionTextContainer = styled(Box)`
  margin-top: 2rem;
`
const AccordionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.2rem',
      },
    }
  }}
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #008174;
  padding-left: 16px;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)``

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '0.3rem',
      },
    }
  }}
  line-height: 2.1;
`
export const LastTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '2rem',
      },
    }
  }}
  line-height: 2.1;
  margin-bottom: 1rem;
`

export default function AccordionFeature() {
  return (
    <AccordionContainer>
      <AccordionTextContainer>
        <AccordionLabelTypography>Privacy policy</AccordionLabelTypography>
      </AccordionTextContainer>
      <AccordionCard
        label="1. Introduction"
        description={[
          `This Privacy Policy covers Tri Tag Rugby and each of their affiliates, members, associates, related entities and subsidiaries (together, "we," "us," "our," and "TTR" or “Tri Tag Rugby”). Please read this Privacy Policy carefully as it describes how we collect, use, disclose and otherwise handle your Personal Information.`,
          `TTR recognises the importance of your privacy and your right to control your Personal Information and is committed to complying with applicable privacy laws (including the Privacy Act).`,
          `TTR is always seeking to better understand how its licensees, participants, Referees and those associated with the game of Tri Tag Rugby interact, communicate and otherwise share Personal Information in order to continue to develop the game and overall experience for all involved. TTR has invested heavily to establish and administer a data storage architecture for processing your Personal Information (Tri Tag Saas platform).`,
        ]}
      />
      <AccordionCard
        label="2. Anonymity and Pseudonymity"
        description={[
          'We offer you the opportunity, wherever it is lawful and practicable, of not identifying yourself, or of using a pseudonym, when entering transactions, or otherwise dealing with, TTR in relation to a particular matter by contacting the TTR Privacy Officer (see clause 11).',
        ]}
      />
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            3. What Kinds of Personal Information Do We Collect?
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <StyledTypography>
            3.1 The types of Personal Information TTR may collect depends on the
            purposes for which it is collected but may include (without
            limitation):
          </StyledTypography>
          <StyledTypography>
            (a) your name, gender, date of birth, occupation, residential
            address, email address, telephone number and other contact details;
          </StyledTypography>
          <StyledTypography>
            (b) your injury and or incident history;
          </StyledTypography>
          <StyledTypography>(c) purchasing preferences; and</StyledTypography>
          <LastTypography>(d) your head shot.</LastTypography>
          <StyledTypography>
            3.2 TTR will not collect Sensitive Information about you unless:
          </StyledTypography>
          <StyledTypography>
            (a) you have consented to the collection of such specific Sensitive
            Information;
          </StyledTypography>
          <StyledTypography>
            (b) TTR is specifically permitted to do so under this privacy policy
            or any other agreement in place between you and TTR or the TTR
            Rules, including for health, medical and investigation of disputes
            (whatever the nature of the dispute);
          </StyledTypography>
          <StyledTypography>(c) purchasing preferences; and</StyledTypography>
          <StyledTypography>
            (c) the collection is required or authorised by law; or
          </StyledTypography>
          <LastTypography>
            (d) the collection is otherwise permitted under applicable privacy
            laws (including the Privacy Act).
          </LastTypography>
          <LastTypography>
            TTR will assume consent to the collection of all Sensitive
            Information that is provided to it for use in accordance with this
            Privacy Policy, unless told otherwise.
          </LastTypography>
          <LastTypography>
            3.3 Website In addition to Personal Information, we use cookies, web
            beacons and other technologies on our website and applications to
            collect information about your usage of our services (for instance,
            third party websites you visit to better understand your preferences
            and what types of products and services you may be interested in).
            Cookies are small text files that help a website to remember the
            preferences of users to improve the experience of using that
            website. In some cases, the cookies that we use may collect some
            Personal Information. TTR will treat this information in the same
            way as other Personal Information we collect. You are free to
            disable cookies on your internet browser to prevent this information
            being collected, however, you will lose the benefit of the enhanced
            website experience that the use of cookies may offer.
          </LastTypography>
          <LastTypography>
            When users visit the TTR website, our systems may record certain
            information about their use of the site, including the web pages
            visited and the time and date of their visit. TTR uses this
            information to help analyse and improve the performance of the TTR
            website.
          </LastTypography>
          <LastTypography>
            Websites linked to the TTR website are not subject to TTR's privacy
            standards, policies or procedures. TTR cannot take any
            responsibility for the collection, use, disclosure or security of
            any Personal Information that you provide to a third party website.{' '}
          </LastTypography>
          <LastTypography>
            3.4 Providing Information Depending on the circumstances, some types
            of information will be required and others might be optional. If you
            do not provide some or all of the information requested, this may
            affect TTR's ability to communicate with you or provide the
            requested products or services.
          </LastTypography>
          <LastTypography>
            By not providing requested information, you may jeopardise your
            ability to participate in programs or Competitions. If it is
            impracticable for TTR to deal with you as a result of you not
            providing the requested information or consent, TTR may refuse to do
            so.
          </LastTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            4. How do we collect your personal information?
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            4.1 Where it is reasonable and practicable to do so, TTR will
            collect your Personal Information from you. If you are a Minor, TTR
            may collect Personal Information about you from your parent or legal
            guardian.
          </LastTypography>
          <LastTypography>
            4.2 Information may be collected when you:
          </LastTypography>
          <StyledTypography>
            (a) Register as a Participant or register a participant because you
            are their legal guardian;
          </StyledTypography>
          <StyledTypography>
            (b) provide details to TTR, or through a consent form, survey,
            feedback form, or incident and or injury report;{' '}
          </StyledTypography>
          <StyledTypography>
            (c) enter Personal Information into, or agree to having your
            Personal Information entered into, one of TTR's online systems
          </StyledTypography>
          <StyledTypography>
            (d) access the TTR website and or App;
          </StyledTypography>
          <StyledTypography>
            (e) contact TTR via email, telephone or mail or engage with TTR via
            social media;
          </StyledTypography>
          <StyledTypography>
            (f) participate in any program, activity, Competition or event run
            by TTR;
          </StyledTypography>
          <StyledTypography>
            (g) purchase tickets to a TTR event whether directly or from an
            authorised agent;
          </StyledTypography>
          <StyledTypography>
            (h) purchase merchandise, products or services from TTR or an
            authorised agent or licensee;
          </StyledTypography>
          <StyledTypography>
            (i) apply for employment or a volunteer position with TTR; and
          </StyledTypography>
          <LastTypography>
            (j) where TTR is required to do so by law (for education, child
            protection, work health and safety laws, charitable collections,
            medical treatment or other legislation in Australia).
          </LastTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            5. Why do we collect, use, hold and disclose your personal
            information
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            5.1 TTR generally only collects, uses, discloses or holds Personal
            Information that is reasonably necessary for TTR to perform one or
            more functions or activities in administering the game of Tri Tag
            Rugby and or creating a better user experience for participants and
            interested observers (the primary purpose), including:
          </LastTypography>
          <StyledTypography>
            (a) organisation, conduct and promotion of Com petitions and Matches
            (including for the purpose of providing information and making
            certain offers to you);
          </StyledTypography>
          <StyledTypography>
            (b) direct communication with you by Tri Tag Rugby Administrators;
          </StyledTypography>
          <StyledTypography>
            (c) disclosure to Tri Tag Rugby partners, unless you have indicated
            that you do not want to receive any communication from Tri Tag
            Rugby;
          </StyledTypography>
          <StyledTypography>
            (d) compliance with TTR Rules, including the administration and
            development of TTR (if you are a Participant);
          </StyledTypography>
          <StyledTypography>
            (e) serving content in accordance with your preferences and previous
            consumption;
          </StyledTypography>
          <StyledTypography>
            (f) serving advertising that is suited to your age; and
          </StyledTypography>
          <LastTypography>
            (g) evaluating and servicing TTR’s relationships with its Partners,
            including to tailor your experience interacting with our Partners.
          </LastTypography>
          <LastTypography>
            TTR may use health information to ensure that programs we operate
            are run safely and in accordance with any special health needs
            Participants may require. Health information may also be kept for
            insurance purposes. In addition, we may use de-identified health
            information and other Sensitive Information to carry out research,
            to prepare submissions to government, or to plan events and
            activities.
          </LastTypography>
          <LastTypography>
            5.2 Wherever practicable, TTR will inform you more specifically of
            the primary purpose of a collection as part of its collection
            statement, which will be made available to you at the time of the
            collection.
          </LastTypography>
          <LastTypography>
            5.3 When we collect your Personal Information, your Personal
            Information will only be used or disclosed for the primary purpose
            for which it was collected, a related secondary purpose, in
            accordance with any express consent you give TTR or as otherwise
            lawfully required (e.g. where your Personal Information is requested
            by a law enforcement agency).
          </LastTypography>
          <LastTypography>
            5.4 TTR may use your personal information for the secondary purpose
            of direct marketing communication, if:
          </LastTypography>
          <StyledTypography>
            (a) TTR collected the information from you;
          </StyledTypography>
          <StyledTypography>
            (b) you would reasonably expect your Personal Information would be
            used or disclosed for direct marketing;
          </StyledTypography>
          <StyledTypography>
            (c) TTR has provided a simple means by which you can request not to
            receive direct marketing; and
          </StyledTypography>
          <LastTypography>
            (d) you have not made a request not to receive direct marketing.
          </LastTypography>
          <LastTypography>
            5.5 Where you would not reasonably expect your Personal Information
            to be used for direct marketing, TTR may use the information for the
            secondary purpose of direct marketing communication only where:
          </LastTypography>
          <StyledTypography>
            (a) you have expressly consented to the use or disclosure for direct
            marketing, or it is impracticable for TTR to seek your consent
            before that use;
          </StyledTypography>
          <StyledTypography>
            (b) you have not made a request to TTR not to receive direct
            marketing communication;
          </StyledTypography>
          <StyledTypography>
            (c) in each direct marketing communication, TTR always prominently
            displays a simple notice or actionable option that you may express a
            wish not to receive any further direct marketing communication; and
          </StyledTypography>
          <StyledTypography>
            (d) each written direct marketing communication by TTR with you sets
            out TTR’s contact details including business address, telephone and
            email address.
          </StyledTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            6. Who do we disclose your personal information to?
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            6.1 Subject to compliance with applicable privacy laws (including
            the Privacy Act) and this Privacy Policy, TTR may also:
          </LastTypography>
          <StyledTypography>
            (a) disclose your Personal Information to its affiliates (including
            without limitation a Licensee and a Competition Administrator) to
            enable them to administer the game of TTR in their area and provide
            related activities and services; and
          </StyledTypography>
          <LastTypography>
            (b) unless you inform TTR otherwise, disclose your Personal
            Information to TTR’s partners as part of the Tri Tag Rugby’s Saas
            platform to enable such recipients to provide you with information,
            materials and promotional opportunities. This sharing of Personal
            Information via TTR’s Saas platform will assist TTR’s and its
            Partners to more effectively administer promotional and information
            sharing activities.
          </LastTypography>
          <LastTypography>
            6.2 TTR may disclose your Personal Information to its service
            providers such as ticketing agents, mail houses or other similar
            third party organisations for the purpose of implementing the
            primary purposes, including fulfilling ticket requests and
            communicating Tri Tag Rugby related offers. For example, TTR uses
            third party credit card payment gateway services on its website and
            applications. If you pay for tickets or services through TTR's
            website and applications, TTR will be required to provide certain
            financial details to the third-party service provider to effect the
            transaction. TTR requires these third party organisations to keep
            your contact details and Personal Information confidential and only
            use them for the designated purpose. TTR may also disclose your
            Personal Information to other service providers which assist TTR in
            the conduct of its operations, such as information services
            providers, information technology providers and other similar third
            party organisations.
          </LastTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            7. Data Quality and security
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            7.1 Tri Tag Rugby will take reasonable steps to:
          </LastTypography>
          <StyledTypography>
            (a) make sure that the Personal Information it collects, uses or
            discloses is accurate, complete and up-to-date; and
          </StyledTypography>
          <LastTypography>
            (b) protect the Personal Information it holds about you or that is
            within its direct control from misuse, interference and loss and
            from unauthorised access, modification or disclosure. Some of the
            security measures TTR uses include strict confidentiality
            requirements of our employees, and service providers, security
            measures for system access and security measures for our website.
          </LastTypography>
          <LastTypography>
            We seek to protect your Personal Information from any unauthorised
            loss, disclosure or access. However, if a serious data breach
            occurs, we must notify you as required under the Privacy Act
            regarding the circumstances of the breach, and must also advise the
            Office of the Australian Information Commissioner.
          </LastTypography>
          <LastTypography>
            7.2 TTR will take reasonable steps to destroy or permanently
            de-identify Personal Information if it is no longer needed for any
            purpose for which the information may be used or disclosed (at your
            request or otherwise) or if you withdraw your consent, unless TTR is
            required by or under law, or a Court order, to retain such
            information. Where TTR is required to destroy or permanently de-
            identify your Personal Information, it will take reasonable steps to
            inform other parties using, holding or disclosing the same Personal
            Information (including any links to, copies of or replication of
            that Personal Information).
          </LastTypography>
          <LastTypography>7.3 Information storage</LastTypography>
          <StyledTypography>
            (a) TTR stores information in different ways, including in paper and
            electronic form.
          </StyledTypography>
          <StyledTypography>
            (b) Much of the information we collect from and about our members is
            added to TTR’s online database. When your information is entered
            into TTR's database, the information may be combined or linked with
            other information held about you.
          </StyledTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            8. Openness, access and correction
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            8.1 TTR strives to provide you with the greatest available control
            of the Personal Information which TTR collects from you or about you
            from and other third parties. Accordingly, TTR is continually
            developing and enhancing the ways which you can access your Personal
            Information (for example, via your communications preferences
            settings).
          </LastTypography>
          <LastTypography>
            8.2 Tri Tag Rugby will provide you with a variety of ways to access,
            review and manage your Personal Information. For example, you can
            opt out of and otherwise manage your communications preferences via
            links in emails and other communications we send to you from time to
            time. You will be responsible for ensuring your Personal Information
            remains accurate, complete and up to date.
          </LastTypography>
          <LastTypography>
            8.3 Separate from the preference settings and upon your request, TTR
            will take reasonable steps to let you know, in general terms, what
            sort of Personal Information it holds, for what purposes, and how it
            collects, holds, uses and discloses that information. Where TTR has
            collected your Personal Information from you directly or with your
            consent, you have a right to receive (upon request) that Personal
            Information from TTR in a structured, commonly used, machine
            readable format and/or a right to instruct TTR to transmit that
            Personal Information to a third party (if technically feasible). In
            such circumstances, we will respond to your request for access
            within 14 days and endeavour to provide the requested information
            within 30 days. If you find that the Personal Information we hold
            about you is inaccurate, incomplete or out-of-date, please contact
            us immediately and we will see that it is corrected.
          </LastTypography>
          <LastTypography>
            8.4 Before supplying any Personal Information to you or updating
            your Personal Information at your request, TTR must be reasonably
            satisfied as to your identity (for example, by asking to see your
            passport or driver's licence). TTR is not under any obligation to
            provide Personal Information to you where TTR is not required to do
            so under applicable privacy laws (including the Privacy Act).
          </LastTypography>
          <LastTypography>
            8.5 If TTR refuses to provide you with access to Personal
            information it holds on you in the manner requested by you, TTR will
            either:
          </LastTypography>
          <StyledTypography>
            (a) take such steps (if any) as are reasonable in the circumstances
            to give access to the information in a way that meets the needs of
            TTR and you; or
          </StyledTypography>
          <StyledTypography>
            (b) provide you with a written notice that sets out:
          </StyledTypography>
          <StyledTypography>
            (i) the reasons for the refusal except to the extent that, having
            regard to the grounds for the refusal, it would be unreasonable to
            do so; and
          </StyledTypography>
          <LastTypography>
            (ii) the mechanisms available to complain about the refusal.
          </LastTypography>
          <LastTypography>8.6 If:</LastTypography>
          <StyledTypography>
            (a) TTR is satisfied that, having regard to a purpose for which the
            Personal Information is held, the information is inaccurate,
            out-of-date, incomplete or irrelevant or misleading; or
          </StyledTypography>
          <LastTypography>
            (b) you request TTR to correct your Personal Information, TTR will
            take reasonable steps to correct the Personal Information to ensure
            that, having regard to the purpose for which it is held, it is
            accurate, up-to-date, complete, relevant and not misleading. TTR
            will not charge you for making such a request or for correcting the
            information.
          </LastTypography>
          <StyledTypography>
            8.7 If TTR refuses to correct the Personal Information at your
            request, TTR will provide you with a written notice that sets out
            the same matters listed in clause 8.5(b).
          </StyledTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            9. Transborder data flows
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            9.1 Subject to clauses 9.2 and 9.3, TTR will not transfer your
            Personal Information to an overseas recipient unless it has first
            taken steps reasonable in the circumstances to ensure that the
            Personal Information that it transfers will not be held, used or
            disclosed by the recipient of the information inconsistently with,
            or otherwise in breach of, applicable privacy laws (including the
            Privacy Act). For example, TTR may adopt appropriate contractual
            clauses with overseas recipients that ensure their compliance with
            applicable privacy laws (including the Privacy Act). This clause
            does not apply to the transfer of Personal Information in accordance
            with TTR Rules, including to facilitate the registration of a Player
            in an international representative tournament.
          </LastTypography>
          <LastTypography>
            9.2 We may disclose your Personal Information to overseas recipients
            for things such as processing registration and hosting services.
          </LastTypography>
          <LastTypography>
            9.3 TTR may transfer Personal Information about you to someone who
            is in a foreign country if:
          </LastTypography>
          <StyledTypography>
            (a) TTR reasonably believes that the recipient is subject to a law
            or binding scheme that has the effect of protecting the information
            in a way that is, overall, substantially similar to applicable
            privacy laws (including the Privacy Act) and there are mechanisms
            available to you to enforce that protection or scheme; and )
          </StyledTypography>
          <StyledTypography>
            (b) you expressly consent to the transfer after TTR has informed you
            that the applicable privacy laws (including the Privacy Act) will no
            longer apply if you provide your express consent.
          </StyledTypography>
        </AccordionDetails>
      </StyledAccordion>
      <AccordionCard
        label="10. Notifiable data breaches scheme"
        description={[
          'In the event of any loss, or unauthorised access or disclosure of your Personal Information that is likely to result in serious harm to you, TTR will investigate and notify you and where applicable the relevant supervisory authority (e.g. the Australian Information Commissioner) within 72 hours of becoming aware of the loss, or unauthorised access or disclosure, in accordance with applicable privacy laws (including the Privacy Act).  ',
        ]}
      />
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            11. Inquiries procedure
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            11.1 All complaints and inquiries concerning your Personal
            Information, including in respect of alleged breaches of this
            Privacy Policy or applicable privacy laws (including the Privacy
            Act), should be directed to the TTR Privacy Officer:
          </LastTypography>
          <StyledTypography>The Privacy Officer</StyledTypography>
          <StyledTypography>
            Level 4, Suite 406 46 – 48 East Esplanade
          </StyledTypography>
          <StyledTypography>Manly NSW, Australia 2095</StyledTypography>
          <LastTypography>
            <Link href="mailto:ttrprivacyofficer@tritagrugby.com">
              ttrprivacyofficer@tritagrugby.com
            </Link>
          </LastTypography>
          <StyledTypography>
            11.2 At all times, privacy complaints will be treated seriously,
            dealt with promptly and confidentially, and will not affect your
            existing obligations or commercial arrangements with TTR.
          </StyledTypography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>
            12. Privacy Policy updates
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>
            This Privacy Policy was created in August 2021. We may update this
            Privacy Policy from time to time, in which case we will provide an
            updated version of the Privacy Policy on our website (
            <Link href="/www.tritagrugby.com" target="_blank">
              www.tritagrugby.com
            </Link>
            ). As such, please check back periodically to see if our Privacy
            Policy has been updated.
          </LastTypography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bolder' }}>13. Definitions</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LastTypography>In this Policy:</LastTypography>
          <LastTypography>
            <b>Competition </b>means any competition, tournament or league
            registered or carried out by the Tri Tag Rugby and or its licensees
          </LastTypography>
          <LastTypography>
            <b>Competition Administrator </b>means a licensee of the Tri Tag
            Rugby product responsible for the conduct and staging of a
            Competition.
          </LastTypography>
          <LastTypography>
            <b>Match </b>means a game of Tri Tag Rugby and includes any match
            staged, participated in, sanctioned by, or played under the auspices
            of Tri Tag Rugby and or a licensee of Tri Tag Rugby.
          </LastTypography>
          <LastTypography>
            <b>Match Official </b>means any person in charge of safety or any
            other person appointed by Tri Tag Rugby or its licensee(s) to assume
            responsibility in connection with a Match.
          </LastTypography>
          <LastTypography>
            <b>Minor </b>means any person younger than 18 years of age.
          </LastTypography>
          <LastTypography>
            <b>Tri Tag Rugby Account </b>means a person's login credentials and
            personal account for accessing TTR’s digital network, including the
            Tri Tag Rugby website and application.
          </LastTypography>
          <LastTypography>
            <b>TTR Rules </b>means its rules, regulations, policies and
            procedures and any other ancillary document that governs TTR or its
            licensees in administering the game of Tri Tag Rugby, as promulgated
            and amended from time to time.
          </LastTypography>
          <StyledTypography>
            <b>Official means: </b>means any person in charge of safety or any
            other person appointed by Tri Tag Rugby or its licensee(s) to assume
            responsibility in connection with a Match.
          </StyledTypography>
          <StyledTypography>
            (a) a Match Official or Team Official;
          </StyledTypography>
          <StyledTypography>
            (b) an employee, consultant, officer or director of TTR, Competition
            Administrator; or
          </StyledTypography>
          <StyledTypography>
            (c) a member of a council, committee, panel or body constituted by
            TTR or a Competition Administrator.
          </StyledTypography>
          <LastTypography>
            <b>Personal Information </b>means any information or an opinion
            about an identified natural person, or a natural person who is
            reasonably identifiable whether the information or opinion is true
            or not and whether the information or opinion is recorded in a
            material form or not.
          </LastTypography>
          <LastTypography>
            <b>Participant </b>means Participants in the game of Tri Tag Rugby
            including a Player, Coach, Official, Referee, Manager and anyone
            else who registers via TTR’s Saas platform in the game of Tri Tag
            Rugby including the players and Officials.
          </LastTypography>
          <LastTypography>
            <b>Player </b>means any person who is, from time to time, registered
            to a competition of Tri Tag Rugby or is selected as a member of a
            Representative Team, whether that person is male or female, junior
            or senior.
          </LastTypography>
          <LastTypography>
            <b>Privacy Act </b>means the Privacy Act 1988 (Cth). Privacy Policy
            means this document as updated from time to time and made available
            on the TTR's website (
            <Link href="/www.tritagrugby.com" target="_blank">
              www.tritagrugby.com
            </Link>
            ).
          </LastTypography>
          <LastTypography>
            <b>Representative Match </b>means a team participating in a
            Representative Match.
          </LastTypography>
          <LastTypography>
            <b>Representative Team </b>means any team selected to participate in
            a Representative Match.
          </LastTypography>
          <LastTypography>
            <b>Tri Tag Rugby Administrators </b>means TTR representatives, or a
            Competition Administrator.
          </LastTypography>
          <LastTypography>
            <b>Tri Tag Rugby Saas platform </b>has the meaning given to that
            term in clause 1 of this Privacy Policy. Tri
          </LastTypography>
          <LastTypography>
            <b>Tag Rugby Partners </b> means any entity that has a commercial
            agreement or arrangement with TTR, or a Competition Administrator.
          </LastTypography>
          <LastTypography>
            <b>Sensitive Information </b>means information or an opinion about a
            natural person's racial or ethnic origin, political opinions.
            membership of political association, religious beliefs or
            affiliations, philosophical beliefs, membership of a professional or
            trade association, membership of a trade union, sexual orientation
            or practices, criminal record that is also Personal Information as
            well as health information, genetic information, biometric
            information for the purposes of verification/identification and
            biometric templates about a natural person.
          </LastTypography>
          <LastTypography>
            <b>Team Official </b>means any personnel involved with the
            management, preparation or participation of a team (whether paid or
            unpaid), including the coaches, managers, medical staff (including
            team or match day doctor), physiotherapists, gear persons and other
            support staff.
          </LastTypography>
        </AccordionDetails>
      </StyledAccordion>
    </AccordionContainer>
  )
}
